<template>
  <div class="mt-1">
    <b-card>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-row class="mb-1">
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0"> </b-col>
          <b-col cols="12" md="7">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
              <b-button variant="primary" @click="addDepartment()">
                <span class="text-nowrap">Add Department</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-table :outlined="true" :fields="fields" responsive="sm" class="table table-sm" :items="filteredDepartments">
          <template #cell(name)="data">
            <div v-if="!data.item.editable">{{ data.value }}</div>
            <div v-else>
              <b-form-input autofocus size="sm" id="name" type="text" v-model="data.item.name"></b-form-input>
            </div>
          </template>

          <template #cell(actions)="data">
            <div>
              <b-button v-if="!data.item.editable" variant="primary" class="mr-25 p-50" @click="toggleEdit(data.item.id)">
                <i class="fa-solid fa-pen-to-square mr-25"></i>
                <span> Edit</span>
              </b-button>

              <b-button v-else variant="primary" class="mr-25 p-50" @click="saveEdit(data.item.id)">
                <i class="fa-solid fa-floppy-disk mr-25"></i>
                <span> Save</span>
              </b-button>

              <b-button v-if="!data.item.editable" variant="primary" @click="deleteDepartment(data.item.id)" class="p-50"> <i class="fa-solid fa-trash mr-25"></i> Delete </b-button>

              <b-button v-else variant="primary" @click="cancelEdit(data.item.id)" class="p-50"> <i class="fa-solid fa-xmark mr-25"></i> Cancel </b-button>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const DEPARTMENT_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(DEPARTMENT_APP_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      departments: [],
      filter: null,
      loading: true,
      fields: [
        { key: 'name', sortable: true, class: 'text-left', thStyle: 'width:80%' },
        { key: 'actions', sortable: false, class: 'text-center', thStyle: 'width:20%' },
      ],
      tempName: null,
    };
  },

  watch: {},

  methods: {
    getDepartments() {
      this.loading = true;
      store
        .dispatch('settings/getAllDepartments')
        .then((res) => {
          this.departments = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$router.push({ name: 'settings-dashboard' });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    toggleEdit(id) {
      const department = this.departments.find((dep) => dep.id === id);

      this.departments.forEach((dep) => {
        dep.editable = false;
        dep.name = dep.tempName;
      });

      if (department) {
        department.editable = true;
      }
    },

    cancelEdit(id) {
      this.departments = this.departments.filter((dep) => dep.id !== undefined);

      if (id !== undefined) {
        const department = this.departments.find((dep) => dep.id === id);

        if (department) {
          department.name = department.tempName;
          department.editable = false;
        }
      }
    },

    saveEdit(id) {
      this.loading = true;
      if (id !== undefined) {
        const department = this.departments.find((dep) => dep.id === id);

        if (department.name != null && department.name != '') {
          if (department) {
            store
              .dispatch('settings/updateDepartment', department)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Department Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.getDepartments();
              })
              .catch((error) => {
                this.formShow = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Please fill in all fields.',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          });

          this.loading = false;
        }
      } else {
        const department = this.departments.find((dep) => dep.id === undefined);

        if (department.name != null && department.name != '') {
          store
            .dispatch('settings/saveDepartment', department)
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Department Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });

              this.getDepartments();
            })
            .catch((error) => {
              this.formShow = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Please fill in all fields.',
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          });
          this.loading = false;
        }
      }
    },

    deleteDepartment(id) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this department?', {
          size: 'sm',
          title: 'Confirm Deletion',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const department = this.departments.find((dep) => dep.id === id);

            if (department) {
              store
                .dispatch('settings/deleteDepartment', department)
                .then((response) => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Successful',
                      text: '✔️ Department Delete Successful',
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  });

                  this.getDepartments();
                })
                .catch((error) => {
                  this.formShow = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'An error occurred',
                      text: 'Please try again later or contact support.',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  });
                });
            }
          }
        });
    },

    addDepartment() {
      this.filter = null;
      this.departments = this.departments.filter((dep) => dep.id !== undefined);

      this.departments.unshift({
        name: '',
        editable: true,
      });
    },
  },

  created() {
    this.getDepartments();
  },

  computed: {
    filteredDepartments() {
      if (!this.filter) {
        return this.departments;
      } else {
        this.departments = this.departments.filter((dep) => dep.id !== undefined);

        this.departments.forEach((dep) => {
          dep.editable = false;
        });

        const searchTerm = this.filter.toLowerCase();
        return this.departments.filter((dep) => dep.name.toLowerCase().includes(searchTerm));
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
